import { FileType } from '@trustblock/types/global.types';

export const maxLengths = {
  name: 50,
  auditName: 100,
  slug: 60,
  url: 2000,
  domain: 30,
  email: 320,
  description: 5000,
  cid: 100,
  repositoryFilePath: 300
} as const;

export const minLengths = {
  repositoryFilePath: 1,
  name: 3,
  slug: 3,
  cid: 10,
  description: 3
} as const;

export const fixedLengths = {
  id: 36,
  authId: 35,
  apiKey: 48,
  evmAddress: 42,
  verificationCode: 6,
  commitHash: 40,
  offChainContractHash: 64,
  pinataApiKey: 683
} as const;

export const paginationDefaults = {
  pageSize: 5
};

export const freeApiCreditsCount = 1000;

export const maxFileSizesPerFileType = {
  [FileType.ProfilePicture]: 5 * 1024 * 1024,
  [FileType.Report]: 25 * 1024 * 1024
} as const;

export const mimeTypesAllowedPerFileType = {
  [FileType.ProfilePicture]: ['image/png', 'image/jpeg', 'image/jpg'],
  [FileType.Report]: ['application/pdf']
} as const;

export const folderPerFileType = {
  [FileType.ProfilePicture]: 'profile-pictures',
  [FileType.Report]: 'reports'
} as const;
