import { IssueSeverity, IssueStatus, IssuesCount, IssuesCountOptionalTotal } from '@trustblock/types/audit.types';
import { Chain, ContractPublic, ContractType, ContractsCount } from '@trustblock/types/contract.types';
import { Tag } from '@trustblock/types/global.types';
import { ProjectPrivate, ProjectsCount } from '@trustblock/types/project.types';

export function issuesDbToIssuesCount(issues: { severity: IssueSeverity; status: IssueStatus }[]): IssuesCount {
  const issuesCount = {
    [IssueStatus.Fixed]: {
      total: 0,
      [IssueSeverity.Low]: 0,
      [IssueSeverity.Medium]: 0,
      [IssueSeverity.High]: 0,
      [IssueSeverity.Critical]: 0
    },
    [IssueStatus.NotFixed]: {
      total: 0,
      [IssueSeverity.Low]: 0,
      [IssueSeverity.Medium]: 0,
      [IssueSeverity.High]: 0,
      [IssueSeverity.Critical]: 0
    },
    total: 0
  };

  issues.forEach((issue) => {
    issuesCount.total += 1;
    issuesCount[issue.status].total += 1;
    issuesCount[issue.status][issue.severity] += 1;
  });

  return issuesCount;
}

export function issuesCountToDbIssues(
  issuesCount: IssuesCount | IssuesCountOptionalTotal
): { severity: IssueSeverity; status: IssueStatus }[] {
  const issues: { status: IssueStatus; severity: IssueSeverity }[] = [];
  const { total: _total, ...issuesCountWithoutTotal } = issuesCount;

  Object.entries(issuesCountWithoutTotal).forEach(([status, statusCount]) => {
    const { total: _totalStatus, ...statusCountWithoutTotal } = statusCount;

    Object.entries(statusCountWithoutTotal).forEach(([severity, severityCount]) => {
      for (let i = 0; i < severityCount; i += 1) {
        issues.push({
          severity: severity as IssueSeverity,
          status: status as IssueStatus
        });
      }
    });
  });

  return issues;
}

export function contractsDbToContractsCount(contracts: ContractPublic[]): ContractsCount {
  const contractsCount = {
    total: 0,
    offChain: 0,
    [Chain.Arbitrum]: 0,
    [Chain.Avalanche]: 0,
    [Chain.Bnbchain]: 0,
    [Chain.Ethereum]: 0,
    [Chain.Fantom]: 0,
    [Chain.Optimism]: 0,
    [Chain.Polygon]: 0,
    [Chain.Zksync]: 0
  };

  contracts.forEach((contract) => {
    contractsCount.total += 1;
    if (contract.type === ContractType.OffChain) {
      contractsCount.offChain += 1;
    } else {
      contractsCount[contract.chain as Chain] += 1;
    }
  });

  return contractsCount;
}

export function projectsDbToProjectsCount(projects: ProjectPrivate[]): ProjectsCount {
  const projectsCount = {
    total: 0,
    [Tag.Analytics]: 0,
    [Tag.Collectibles]: 0,
    [Tag.Finance]: 0,
    [Tag.Gaming]: 0,
    [Tag.Other]: 0,
    [Tag.Security]: 0,
    [Tag.Social]: 0,
    [Tag.Wallet]: 0
  };

  projects.forEach((project) => {
    projectsCount.total += 1;
    project.tags.forEach((tag) => {
      projectsCount[tag] += 1;
    });
  });

  return projectsCount;
}

export function extractDomain(url: string): string {
  const urlObj = new URL(url);
  const { hostname } = urlObj;
  const parts = hostname.split('.');

  if (parts.length > 2) {
    return parts.slice(-2).join('.');
  }
  return hostname;
}

type RecursivelyReplaceNullWithUndefined<T> = T extends null
  ? undefined
  : T extends Date
    ? T
    : {
        [K in keyof T]: T[K] extends (infer U)[]
          ? RecursivelyReplaceNullWithUndefined<U>[]
          : RecursivelyReplaceNullWithUndefined<T[K]>;
      };

export function nullsToUndefined<T>(obj: T): RecursivelyReplaceNullWithUndefined<T> {
  if (obj === null) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
    return undefined as any;
  }

  if (obj?.constructor.name === 'Object') {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in obj) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, no-param-reassign, @typescript-eslint/no-explicit-any
      const newValue = nullsToUndefined(obj[key]) as any;
      if (newValue !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, no-param-reassign
        obj[key] = newValue;
      } else {
        // eslint-disable-next-line no-param-reassign
        delete obj[key];
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
  return obj as any;
}
