import { Chain, ContractType } from '@trustblock/types/contract.types';

export type ChainsIds = (typeof chainsData)[Chain]['id'];

export const chainIdToChainName = {
  1: Chain.Ethereum,
  137: Chain.Polygon,
  43114: Chain.Avalanche,
  56: Chain.Bnbchain,
  42161: Chain.Arbitrum,
  10: Chain.Optimism,
  250: Chain.Fantom,
  324: Chain.Zksync
} as const;

export const chainsData = {
  [Chain.Ethereum]: {
    label: 'Ethereum',
    internalValue: 'ethereum',
    id: 1,
    icon: 'Ethereum',
    scanName: 'Etherscan',
    getAddressScanUrl: (address: string) => `https://etherscan.io/address/${address}`,
    getTransactionScanUrl: (txHash: string) => `https://etherscan.io/tx/${txHash}`
  },
  [Chain.Polygon]: {
    label: 'Polygon',
    internalValue: 'polygon',
    id: 137,
    icon: 'Polygon',
    scanName: 'Polygonscan',
    getAddressScanUrl: (address: string) => `https://polygonscan.com/address/${address}`,
    getTransactionScanUrl: (txHash: string) => `https://polygonscan.com/tx/${txHash}`
  },
  [Chain.Avalanche]: {
    label: 'Avalanche',
    internalValue: 'avalanche',
    id: 43114,
    icon: 'Avalanche',
    scanName: 'Snowtrace',
    getAddressScanUrl: (address: string) => `https://snowtrace.io/address/${address}`,
    getTransactionScanUrl: (txHash: string) => `https://snowtrace.io/tx/${txHash}`
  },
  [Chain.Bnbchain]: {
    label: 'BNB Chain',
    internalValue: 'bnbchain',
    id: 56,
    icon: 'Bnb',
    scanName: 'Bscscan',
    getAddressScanUrl: (address: string) => `https://bscscan.com/address/${address}`,
    getTransactionScanUrl: (txHash: string) => `https://bscscan.com/tx/${txHash}`
  },
  [Chain.Arbitrum]: {
    label: 'Arbitrum',
    internalValue: 'arbitrum',
    id: 42161,
    icon: 'Arbitrum',
    scanName: 'Arbiscan',
    getAddressScanUrl: (address: string) => `https://arbiscan.io/address/${address}`,
    getTransactionScanUrl: (txHash: string) => `https://arbiscan.io/tx/${txHash}`
  },
  [Chain.Optimism]: {
    label: 'Optimism',
    internalValue: 'optimism',
    id: 10,
    icon: 'Optimism',
    scanName: 'Optimismscan',
    getAddressScanUrl: (address: string) => `https://optimistic.etherscan.io/address/${address}`,
    getTransactionScanUrl: (txHash: string) => `https://optimistic.etherscan.io/tx/${txHash}`
  },
  [Chain.Fantom]: {
    label: 'Fantom',
    internalValue: 'fantom',
    id: 250,
    icon: 'Fantom',
    scanName: 'FTMscan',
    getAddressScanUrl: (address: string) => `https://ftmscan.com/address/${address}`,
    getTransactionScanUrl: (txHash: string) => `https://ftmscan.com/tx/${txHash}`
  },
  [Chain.Zksync]: {
    label: 'zkSync',
    internalValue: 'zksync',
    id: 324,
    icon: 'Zksync',
    scanName: 'zkSync Explorer',
    getAddressScanUrl: (address: string) => `https://explorer.zksync.io/address/${address}`,
    getTransactionScanUrl: (txHash: string) => `https://explorer.zksync.io/tx/${txHash}`
  },
  offChain: {
    label: 'Off-chain',
    internalValue: ContractType.OffChain,
    id: -1,
    icon: 'ServerOff'
  }
} as const;
