import { fixedLengths, maxLengths, minLengths } from '@trustblock/constants/configuration.constants';
import { z } from 'zod';
import { Link } from '@trustblock/types/global.types';
import { linksValidator, paginationValidator, tagsValidator } from './global.validators';
import { testAtLeastOneFieldRequired, testIsDomain } from './zodCustomValidators';
import { contractOnChainValidator } from './contract.validators';

export const projectValidator = z.object({
  id: z.string().uuid().length(fixedLengths.id),
  domain: z.string().superRefine(testIsDomain),
  slug: z.string().min(minLengths.slug).max(maxLengths.slug),
  email: z.string().email(),
  name: z.string().min(minLengths.name).max(maxLengths.name),
  createdAt: z.date()
});
export type ProjectValidator = z.infer<typeof projectValidator>;

export const projectMetricsValidator = z.object({
  projectId: z.string().uuid().length(fixedLengths.id),
  totalValue: z.number(),
  totalUsers: z.number(),
  totalTransactions: z.number(),
  evaluatedAt: z.date()
});
export type ProjectMetricsValidator = z.infer<typeof projectMetricsValidator>;

export const createProjectValidator = projectValidator
  .omit({ slug: true, id: true, domain: true, createdAt: true })
  .extend({ tags: tagsValidator })
  .extend({
    links: linksValidator.omit({ targetId: true })
  });
export type CreateProjectParams = z.infer<typeof createProjectValidator>;

export const getProjectValidator = z.object({ links: linksValidator.pick({ [Link.Website]: true }) });
export type GetProjectParams = z.infer<typeof getProjectValidator>;

export const findProjectValidatorBase = projectValidator
  .pick({ domain: true, slug: true })
  .partial()
  .merge(contractOnChainValidator.pick({ evmAddress: true, chain: true }))
  .partial();

export const findProjectValidator = findProjectValidatorBase.superRefine(
  testAtLeastOneFieldRequired(['evmAddress', 'chain', 'slug', 'domain'])
);

export const redirectToProjectWidgetPageValidator = findProjectValidatorBase
  .extend({
    iframeId: z.string()
  })
  .superRefine(testAtLeastOneFieldRequired(['evmAddress', 'chain', 'slug', 'domain']));
export type RedirectToProjectWidgetPageParams = z.infer<typeof redirectToProjectWidgetPageValidator>;

export const searchProjectsValidator = z
  .object({
    searchTerm: z.string().min(1).optional()
  })
  .merge(paginationValidator);
export type SearchProjectsParams = z.infer<typeof searchProjectsValidator>;

export const getProjectBySlugValidator = z.object({
  slug: z.string().min(minLengths.slug).max(maxLengths.slug)
});
