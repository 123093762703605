import { fixedLengths, maxLengths, minLengths } from '@trustblock/constants/configuration.constants';
import { ContractType, contractTypes, Chain, chains } from '@trustblock/types/contract.types';
import z from 'zod';
import { testIsEvmAddress, testIsUrl } from './zodCustomValidators';

const contractBaseValidator = z.object({
  type: z.enum(contractTypes as [ContractType, ...ContractType[]]),
  id: z.string().uuid().length(fixedLengths.id),
  projectId: z.string().uuid().length(fixedLengths.id),
  createdAt: z.date()
});

export const contractOnChainValidator = contractBaseValidator.omit({ type: true }).extend({
  type: z.literal(ContractType.OnChain),
  contractId: z.string().uuid().length(fixedLengths.id),
  chain: z.enum(chains as [Chain, ...Chain[]]),
  evmAddress: z.string().superRefine(testIsEvmAddress)
});

export const contractOffChainValidator = contractBaseValidator.omit({ type: true }).extend({
  type: z.literal(ContractType.OffChain),
  contractId: z.string().uuid().length(fixedLengths.id),
  repositoryFullUrlHash: z.string().length(fixedLengths.offChainContractHash),
  repositoryUrl: z.string().url().superRefine(testIsUrl),
  repositoryCommitHash: z.string().length(fixedLengths.commitHash),
  repositoryFilePath: z.string().min(minLengths.repositoryFilePath).max(maxLengths.repositoryFilePath)
});

export const contractValidator = z.discriminatedUnion('type', [
  contractOnChainValidator.omit({ contractId: true }),
  contractOffChainValidator.omit({ contractId: true })
]);
export type ContractValidator = z.infer<typeof contractValidator>;

export const contractMetricsValidator = z.object({
  contractId: z.string().uuid().length(fixedLengths.id),
  totalValue: z.number(),
  totalUsers: z.number(),
  totalTransactions: z.number(),
  evaluatedAt: z.date()
});
export type ContractMetricsValidator = z.infer<typeof contractMetricsValidator>;

export const createContractValidator = z.discriminatedUnion('type', [
  contractOnChainValidator.omit({
    id: true,
    contractId: true,
    projectId: true,
    createdAt: true
  }),
  contractOffChainValidator.omit({
    id: true,
    repositoryFullUrlHash: true,
    contractId: true,
    projectId: true,
    createdAt: true
  })
]);
export type CreateContractValidator = z.infer<typeof createContractValidator>;
