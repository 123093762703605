import { Link, Tag, tags } from '@trustblock/types/global.types';
import { z } from 'zod';
import { fixedLengths } from '@trustblock/constants/configuration.constants';
import { testIsUrl, testSocialProfileLink } from './zodCustomValidators';

export const linksValidator = z
  .object({
    targetId: z.string().uuid().length(fixedLengths.id),
    [Link.Website]: z.string().url().superRefine(testIsUrl)
  })
  .merge(
    z.object({
      [Link.Twitter]: z.string().url().optional().superRefine(testSocialProfileLink),
      [Link.Discord]: z.string().url().optional().superRefine(testSocialProfileLink),
      [Link.Telegram]: z.string().url().optional().superRefine(testSocialProfileLink),
      [Link.Github]: z.string().url().optional().superRefine(testSocialProfileLink),
      [Link.Youtube]: z.string().url().optional().superRefine(testSocialProfileLink),
      [Link.Linkedin]: z.string().url().optional().superRefine(testSocialProfileLink)
    })
  );

export type LinksValidator = z.infer<typeof linksValidator>;

export const tagsValidator = z.array(z.enum(tags as [Tag, ...Tag[]]));

export const paginationValidator = z.object({
  page: z.string().regex(/^(?!0+$)\d+$/)
});
