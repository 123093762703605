import type {
  AuditorMetricsValidator,
  AuditorMetricsStatsSubValidator,
  AuditorValidator
} from '@trustblock/validators/auditor.validators';
import type { RequestResponseBase, RequestResponsePagination } from './global.types';
import type { UserPrivate, UserPublic, UserType } from './user.types';
import type { AuditPublic, IssuesCount } from './audit.types';
import type { ContractsCount } from './contract.types';
import type { ProjectsCount } from './project.types';

export enum AuditorType {
  Firm = 'firm',
  DaoCommunity = 'dao_community',
  Individual = 'individual'
}
export const auditorTypes = Object.values(AuditorType);

export enum AuditorHeadcount {
  OneToFive = 'one_to_five',
  FiveToTwenty = 'five_to_twenty',
  TwentyToFifty = 'twenty_to_fifty',
  FiftyPlus = 'fifty_plus'
}
export const auditorHeadcounts = Object.values(AuditorHeadcount);

export enum ProspectChain {
  Evm = 'evm',
  Cosmos = 'cosmos',
  Solana = 'solana',
  Near = 'near',
  Starknet = 'starknet',
  Substrate = 'substrate'
}
export const prospectChains = Object.values(ProspectChain);

export type AuditorMetricsStatsSubPrivate = AuditorMetricsStatsSubValidator;
export type AuditorMetricsPrivate = AuditorMetricsValidator & {
  value: AuditorMetricsStatsSubValidator;
  users: AuditorMetricsStatsSubValidator;
  transactions: AuditorMetricsStatsSubValidator;
};
export type AuditorPrivate = Omit<UserPrivate, 'type'> &
  Omit<AuditorValidator, 'userId'> & {
    metrics: Omit<AuditorMetricsValidator, 'auditorId'> & {
      value: AuditorMetricsStatsSubValidator;
      users: AuditorMetricsStatsSubValidator;
      transactions: AuditorMetricsStatsSubValidator;
    };
    userType: UserType.Auditor;
  };
export type AuditorPublic = Omit<UserPublic, 'type' | 'id' | 'authId'> &
  Omit<AuditorValidator, 'userId'> & {
    metrics: Omit<AuditorMetricsValidator, 'auditorId' | 'evaluatedAt'> & {
      value: Omit<AuditorMetricsStatsSubValidator, 'auditorMetricsStatsSubId'>;
      users: Omit<AuditorMetricsStatsSubValidator, 'auditorMetricsStatsSubId'>;
      transactions: Omit<AuditorMetricsStatsSubValidator, 'auditorMetricsStatsSubId'>;
    };
    issuesCount: IssuesCount;
    contractsCount: ContractsCount;
    projectsCount: ProjectsCount;
  };

export interface CreateAuditorResponseData {
  slug: string;
}
export type CreateAuditorResponse = RequestResponseBase & CreateAuditorResponseData;

export type UpdateAuditorResponse = RequestResponseBase;

export type GetAuditorsSlugsResponse = RequestResponseBase & { items: string[] };

export type SearchAuditorsResponseItemData = Pick<AuditorPublic, 'name' | 'profilePictureUrl' | 'slug'> & {
  tvs: number;
  auditsTotal: number;
  contractsCount: ContractsCount;
  projectsCount: ProjectsCount;
};
export type SearchAuditorsResponse = RequestResponseBase & RequestResponsePagination<SearchAuditorsResponseItemData>;

export type GetAuditorPageDataResponseData = AuditorPublic & {
  audits: (Pick<AuditPublic, 'issuesCount' | 'name' | 'createdAt' | 'id'> & {
    auditor: {
      slug: string;
      name: string;
    };
    contractsCount: ContractsCount;
  })[];
};
export type GetAuditorPageDataResponse = RequestResponseBase & GetAuditorPageDataResponseData;

export type GetAuditorWidgetPageDataResponseData = Pick<AuditorPublic, 'slug' | 'metrics' | 'name'> & {
  domain: string;
  auditsTotal: number;
  issuesTotal: number;
};
export type GetAuditorWidgetPageDataResponse = RequestResponseBase & GetAuditorWidgetPageDataResponseData;

export type GetUploadReportAuthorizationResponse = RequestResponseBase & {
  url: string;
  apiKey: string;
  fileName: string;
};
