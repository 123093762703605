import { Tag } from '@trustblock/types/global.types';
import { IconName } from '@trustblock/components/standalone/Icon/Icon';
import { AuditorHeadcount, AuditorType } from '@trustblock/types/auditor.types';

interface TagData {
  label: string;
  icon: IconName;
  value: Tag;
}

export const tagsData: Record<Tag, TagData> = {
  [Tag.Analytics]: {
    label: 'Analytics',
    icon: 'CandlestickChart',
    value: Tag.Analytics
  },
  [Tag.Collectibles]: {
    label: 'Collectibles',
    icon: 'Collectibles',
    value: Tag.Collectibles
  },
  [Tag.Finance]: {
    label: 'Finance',
    icon: 'Coins',
    value: Tag.Finance
  },
  [Tag.Gaming]: {
    label: 'Gaming',
    icon: 'Gamepad',
    value: Tag.Gaming
  },
  [Tag.Other]: {
    label: 'Other',
    icon: 'Braces',
    value: Tag.Other
  },
  [Tag.Security]: {
    label: 'Governance',
    icon: 'Radar',
    value: Tag.Security
  },
  [Tag.Social]: {
    label: 'Social',
    icon: 'HeartHandshake',
    value: Tag.Social
  },
  [Tag.Wallet]: {
    label: 'Wallet',
    icon: 'Wallet',
    value: Tag.Wallet
  }
} as const;

export const auditorTypesData = {
  [AuditorType.Individual]: { label: 'Individual auditor', value: AuditorType.Individual },
  [AuditorType.DaoCommunity]: { label: 'Dao / Community', value: AuditorType.DaoCommunity },
  [AuditorType.Firm]: { label: 'Auditing firm', value: AuditorType.Firm }
} as const;

export const auditorHeadcountsData = {
  [AuditorHeadcount.OneToFive]: { label: '1-5 authors', value: AuditorHeadcount.OneToFive },
  [AuditorHeadcount.FiveToTwenty]: { label: '5-20 authors', value: AuditorHeadcount.FiveToTwenty },
  [AuditorHeadcount.TwentyToFifty]: { label: '20-50 authors', value: AuditorHeadcount.TwentyToFifty },
  [AuditorHeadcount.FiftyPlus]: { label: '+50 authors', value: AuditorHeadcount.FiftyPlus }
} as const;
